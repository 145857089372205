import * as yup from 'yup';
import { PATTERNS } from '../constants/patterns';
import { isPostalCode, isPostalCodeExist } from './postal-code';
import t from '../util/i18n';

export const memberSchema = {
  'member[family_name]': {
    validation: yup
      .string()
      .trim()
      .label(t('validations.member.family_name.label'))
      .required()
      .max(50),
    count: true,
  },

  'member[first_name]': {
    validation: yup
      .string()
      .trim()
      .label(t('validations.member.first_name.label'))
      .required()
      .max(50),
    count: true,
  },

  'member[postal_code]': {
    validation: yup
      .string()
      .label(t('validations.member.postal_code.label'))
      .notRequired()
      .test('is-invalid-postal-code', t('validations.member.postal_code.format'), isPostalCode)
      .test('is-postal-code-exist', t('validations.member.postal_code.exist'), isPostalCodeExist),
  },

  'member[prefecture_id]': {
    validation: yup
      .string()
      .label(t('validations.member.prefecture.label'))
      .required(),
    count: true,
  },

  'member[city_id]': {
    validation: yup
      .string()
      .label(t('validations.member.city.label'))
      .required(),
    count: true,
  },

  'member[tel]': {
    validation: yup
      .string()
      .label(t('validations.member.tel.label'))
      .required()
      .matches(PATTERNS.TEL, t('validations.member.tel.format')),
    count: true,
  },

  'member[employment_status]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      const value = $form.find('[name="member[employment_status]"]:checked').val();

      if (value === undefined) {
        return 0;
      }

      return +value || 0;
    },
    getStyleTargetElement: ($form: JQuery<HTMLElement>) => {
      return $form.find('[name="member[employment_status]"]').siblings('label');
    },
    validation: yup
      .number()
      .label(t('validations.member.employment_status.label'))
      .min(1, t('validations.member.employment_status.required')),
    count: true,
  },

  'member[job_qualification]': {
    getValue: ($form: JQuery<HTMLInputElement>) => {
      return {
        isRequired: $form.find('[name="member[job_qualification]"]').length > 0,
        value: $form.find('[name="member[job_qualification]"]:checked').val(),
      };
    },
    getStyleTargetElement: ($form: JQuery<HTMLInputElement>) => {
      return $form.find('[name="member[job_qualification]"]').closest('.js-form-manager__fieldset').find('label');
    },
    validation: yup
      .mixed()
      .test('is-required', t('validations.application.job_qualification.required'), (value: any) => {
        return value.isRequired === false || value.value === 'true';
      }),
    count: (value: any) => {
      return value.isRequired;
    },
  },
};
