import $ from 'jquery';
import { Modal } from './modal';

class SearchConditionSaveFormModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
    });
  }

  init = () => {
    this.modal.init();
  }

  show = () => {
    this.modal.show({});
  }
}

export {
  SearchConditionSaveFormModal,
};
