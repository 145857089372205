import $ from 'jquery';

class ScrollToTop {
  init() {
    $(document.body).on('click', '.js-scroll-to-top', (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }
}

class ScrollToReadStart {
  init() {
    if (window.location.hash === '#read-start') {
      return;
    }
    const $readStart = $('.js-read-start');
    if ($readStart.length > 0) {
      window.scrollTo({
        top: $readStart.offset().top,
        behavior: 'smooth',
      });
    }
  }
}

class ScrollToTarget {
  init() {
    $(document.body).on('click', '.js-scroll', (e) => {
      e.preventDefault();
      this.scrollTo(e.target);
    });
  }

  scrollTo = (evTarget) => {
    const href = $(evTarget).attr('href');
    const $target = $(href);

    window.scrollTo({
      top: $target.offset().top,
      behavior: 'smooth',
    });
  }
}

export {
  ScrollToTop,
  ScrollToTarget,
  ScrollToReadStart,
};
