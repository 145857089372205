import $ from 'jquery';
import queryString from 'query-string';
import { emitter } from './util/event-emitter';
import { flatten, removeDuplicated, findDuplicated } from './util/array';
import { RefineSearchModal } from './modules/modal';
import { CheckedGroupSwitcher } from './checked-group-switcher';
import { Accordion } from './accordion';
import { UpperLimitManager, CheckboxSynchronizer } from './checkbox-synchronizer';
import { FootBarLiftingPreventer } from './modules/foot-bar-lifting-preventer';

const CURRENT_CLASSNAME = 'js-refine-search--is-current';

const filterDuplicatedParameters = (query) => {
  const parsed = queryString.parse(query);
  const params = Object.keys(parsed).reduce((accumulator, current) => {
    accumulator[current] = Array.isArray(parsed[current]) ? removeDuplicated(parsed[current]) : parsed[current];// eslint-disable-line no-param-reassign
    return accumulator;
  }, {});

  return queryString.stringify(params);
};

class CheckboxManager {
  static attachEvent = ($wrapper, done) => {
    $wrapper.on('change', 'input:checkbox', (e) => {
      CheckboxManager.update(e.target, done);
    });
  }

  static syncChecked = (targetBody) => {
    const $checkedInputs = $(targetBody).find('input:checkbox');

    $checkedInputs.each((_, el) => {
      CheckboxSynchronizer.syncLazyLoaded(el, { type: 'data' });
    });
  }

  static update = (target, done) => {
    let $targetInputs;

    $targetInputs = CheckboxSynchronizer.sync(target, {
      type: 'data',
    });
    if (UpperLimitManager.isUpperLimitOver(target)) {
      UpperLimitManager.alert(target);
      $targetInputs.prop('checked', false);
      return;
    }

    $targetInputs = CheckboxManager.handleToggle(target);
    if (UpperLimitManager.isUpperLimitOver(target)) {
      UpperLimitManager.alert(target);
      $targetInputs.prop('checked', false);
      return;
    }

    UpperLimitManager.alert(target);
    if (typeof done === 'function') {
      done();
    }
  }

  static handleToggle = (target) => {
    const $target = $(target);

    if ($target.hasClass('js-checked-group-switcher__input')) {
      return CheckedGroupSwitcher.toggleController(target);
    }
    if ($target.hasClass('js-checked-group-switcher__controller')) {
      return CheckedGroupSwitcher.toggleInputs(target);
    }
    return $target;
  }
}

class RefineSearch {
  constructor(el) {
    this.$wrapper = $(el);
    this.$form = $(el).closest('form');
    this.$searchCounterViewer = this.$form.find('.js-refine-search__counter');
    this.$submit = this.$wrapper.find(':submit');
    this.$hiddenFields = $('.js-refine-search-hidden').find('input[type="hidden"]');
    this.$footBar = this.$wrapper.find('.js-refine-search__foot-bar');
    this.hiddenValues = {
      city: [...(this.$hiddenFields.filter((_, field) => field.name === 'city_id[]'))].map(field => field.value),
      feature: [...(this.$hiddenFields.filter((_, field) => field.name === 'feature[]'))].map(field => field.value),
      employmentType: [...(this.$hiddenFields.filter((_, field) => field.name === 'employment_type[]'))].map(field => field.value),
      salaryMonthlyBottom: [...(this.$hiddenFields.filter((_, field) => field.name === 'salary_monthly_bottom'))].map(field => field.value)[0],
      salaryHourlyBottom: [...(this.$hiddenFields.filter((_, field) => field.name === 'salary_hourly_bottom'))].map(field => field.value)[0],
      station: [...(this.$hiddenFields.filter((_, field) => field.name === 'station_id[]'))].map(field => field.value),
      line: [...(this.$hiddenFields.filter((_, field) => field.name === 'line_id[]'))].map(field => field.value),
    };

    this.accordions = this.$wrapper.find('.js-refine-search-accordion').map((_, accordion) => {
      return new Accordion(accordion, {
        beforeOpen: async ({ targetBody }) => {
          await CheckboxManager.syncChecked(targetBody);
        },
      });
    }).get();
    this.searchType = this.$wrapper.data('search-type');

    this.modal = new RefineSearchModal({
      modalId: this.$wrapper.closest('.js-modal').data('modal-id'),
      onHide: () => {
        this.clear({ $content: this.$wrapper });
        this.restoreDisabledByPriority();
        this.restoreCurrentHiddenFromCache({ $current: this.$wrapper });
        this.unmarkAsCurrent();
        this.accordions.map(accordion => accordion.closeAll());
      },
      onShow: async () => {
        await this.markAsCurrent(this.$wrapper);
        await this.restoreAccordionInitiallyOpened();
        await this.disableByPriority();
        await this.restoreFormValueFromHiddenFields();
        this.$wrapper.find('.js-checked-group-switcher').map((_, switcher) => {
          return CheckedGroupSwitcher.toggleController($(switcher).find('.js-checked-group-switcher__input').eq(0)[0]);
        }).get();
        await this.updateSearchCounter();
      },
    });
    this.footBarLiftingPreventer = new FootBarLiftingPreventer({
      $wrapper: this.$wrapper,
      $footBar: this.$footBar,
    });
  }

  init = () => {
    this.accordions.map(accordion => accordion.init());
    this.modal.init();
    this.updateSearchCounter();
    this.footBarLiftingPreventer.init();
    this.$form.on('change', 'select', this.updateSearchCounter);
    emitter.on('gridTab:change', async ({ $content, $current }) => {
      if (this.$wrapper.closest($content).length > 0) {
        await this.markAsCurrent(this.$wrapper);
        await this.restoreFormValueFromHiddenFields();
        await this.updateSearchCounter();
      } else {
        await this.clear({ $content: $current });
        await this.restoreCurrentHiddenFromCache({ $current });
        await this.unmarkAsCurrent();
      }
    });

    CheckboxManager.attachEvent(this.$wrapper, () => {
      this.disableLineByStation();
      this.updateSearchCounter();
    });

    this.$form.on('submit', this.handleSubmit);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.isCurrent()) {
      return;
    }

    this.removeValuelessFields();
    this.removeDuplicatedFields();
    this.$form.off('submit').submit();
  }

  removeValuelessFields = () => {
    this.$form.find('input, select, textarea').filter((_, el) => !el.value).prop('disabled', true);
  }

  removeDuplicatedFields = () => {
    const parsed = queryString.parse(this.$form.serialize());
    const duplicatedFields = Object.keys(parsed).reduce((accumulator, current) => {
      if (Array.isArray(parsed[current])) {
        accumulator.push({
          key: current,
          values: findDuplicated(parsed[current]),
        });
      }
      return accumulator;
    }, []);

    duplicatedFields.forEach((duplicated) => {
      const $hiddenByKey = this.$form.find('.js-refine-search-hidden').find(`input:hidden[name="${ duplicated.key }"]`);

      duplicated.values.forEach((value) => {
        $hiddenByKey.filter((_, el) => $(el).val() === value).remove();
      });
    });

    // #20336 により検索パネルのチェックボックスが PC, SP それぞれに存在する＆両方チェックが入る状態に
    // 送信前に、自環境じゃない方のチェックボックスを外す
    // FIXME: あくまで PC,SP の重複を排除してるのみ。根本的には、重複してる値のチェックボックスを外すのが正攻法
    //   - 駅・沿線モーダルにおいて、複数路線に跨って存在する駅など、他にも重複するパターンはある
    let ignoreCheckBoxClass;
    if (window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches) {
      ignoreCheckBoxClass = '.js-ignore-checkbox-pc';
    } else {
      ignoreCheckBoxClass = '.js-ignore-checkbox-sp';
    }
    this.$form.find(ignoreCheckBoxClass).filter(':checked').remove();
  }

  disableByPriority = () => {
    const priority = this.$wrapper.closest('.js-modal').data('refine-search-priority');
    const $cityFields = this.$form.find('[name="city_id[]"]').not(':disabled');
    const $prefectureFields = this.$form.find('[name="prefecture_id"]').not(':disabled');

    // 駅沿線検索のモーダルを表示するとき、市区町村、都道府県、政令指定都市は駅沿線とは不正なパラメータの組み合わせになるケースがあるため、
    // 適宜検索条件から除外する
    switch (priority) {

      case 'prefecture-and-city':
        [$cityFields, $prefectureFields].forEach(($el) => {
          $el.prop('disabled', true).addClass('js-refine-search-field-disabled-by-priority');
        });
        break;
      case 'city':
        [$cityFields].forEach(($el) => {
          $el.prop('disabled', true).addClass('js-refine-search-field-disabled-by-priority');
        });
        break;
      default:
        break;
    }
  }

  // 駅が選択されている場合のみ沿線idを検索条件から除外する
  // 駅idと沿線idがいずれも指定されていると、複数沿線に跨る駅が指定されているケースで不正なパラメータの組み合わせになるため
  disableLineByStation = () => {
    const $checkedStations = this.$form.find('[name="station_id[]"]:checked');
    const $lineFields = this.$form.find('[name="line_id[]"]');

    if ($checkedStations.length > 0) {
      $lineFields.prop('disabled', true).addClass('js-refine-search-field-disabled-by-priority');
    } else {
      $lineFields.prop('disabled', false).removeClass('js-refine-search-field-disabled-by-priority');
    }
  }

  restoreDisabledByPriority = () => {
    $('.js-refine-search-field-disabled-by-priority').prop('disabled', false).removeClass('js-refine-search-field-disabled-by-priority');
  }

  restoreAccordionInitiallyOpened = () => {
    this.accordions.map((accordion) => {
      const $initialOpened = accordion.$wrapper.find('[data-refine-search-accordion-initial-opened]');

      return $initialOpened.map((_, opened) => {
        return accordion.open(opened);
      });
    });
  }

  markAsCurrent = () => {
    this.$wrapper.addClass(CURRENT_CLASSNAME);
  }

  unmarkAsCurrent = () => {
    this.$wrapper.removeClass(CURRENT_CLASSNAME);
  }

  isCurrent = () => {
    return !this.$wrapper.closest('.js-modal').length || this.$wrapper.hasClass(CURRENT_CLASSNAME);
  }

  getAPIURL = () => {
    if (this.searchType === 'facility') {
      return '/api/users/facilities/count.json';
    }
    return '/api/users/job_offers/count';

  }

  updateSearchCounter = async () => {
    if (!this.isCurrent()) {
      return;
    }

    const response = await $.ajax({
      type: 'GET',
      url: this.getAPIURL(),
      data: filterDuplicatedParameters(`${ this.$form.serialize() }&hw=1`),
    });

    this.$searchCounterViewer.text(response.count);
    await this.updateSubmitStatus([response.count]);
  }

  updateSubmitStatus = (counts) => {
    const isAbleToSubmit = counts.every(count => count > 0);
    this.$submit.prop('disabled', !isAbleToSubmit);
  }

  // .js-refine-search-hiddenとmodal内の入力項目で値が重複するのを避けるため、重複項目は削除する
  // modal閉じる際に、this.restoreCurrentHiddenFromCache()で復元
  evacuateUnnecessaryHiddenFields = (fields) => {
    const hiddenFields = Object.keys(fields).reduce((accumulator, current) => {
      if (fields[current].length) {
        accumulator[current] = fields[current].eq(0).attr('name');// eslint-disable-line
      }
      return accumulator;
    }, {});

    const $hiddenFields = $('.js-refine-search-hidden').children();
    Object.keys(hiddenFields).forEach((current) => {
      $hiddenFields.filter(`input[name="${ hiddenFields[current] }"]`).remove();
    });
  }

  restoreCurrentHiddenFromCache = ({ $current }) => {
    const $currentHiddenFields = this.$hiddenFields.filter((index, el) => {
      return $current.find(`[name="${ $(el).attr('name') }"]`).length > 0;
    });
    $('.js-refine-search-hidden').append($currentHiddenFields);
  }

  restoreFormValueFromHiddenFields = () => {
    const fields = {
      $city: this.$wrapper.find('input[type="checkbox"][name="city_id[]"]').filter((_, target) => this.hiddenValues.city.includes(target.value)),
      $feature: this.$wrapper.find('input[type="checkbox"][name="feature[]"]').filter((_, target) => this.hiddenValues.feature.includes(target.value)),
      $employmentType: this.$wrapper.find('input[type="checkbox"][name="employment_type[]"]').filter((_, target) => this.hiddenValues.employmentType.includes(target.value)),
      $salaryMonthlyBottom: this.$wrapper.find('select[name="salary_monthly_bottom"]'),
      $salaryHourlyBottom: this.$wrapper.find('select[name="salary_hourly_bottom"]'),
      $station: this.$wrapper.find('input[type="checkbox"][name="station_id[]"]').filter((_, target) => this.hiddenValues.station.includes(target.value)),
    };

    this.evacuateUnnecessaryHiddenFields(fields);

    fields.$city.prop('checked', true);
    fields.$feature.prop('checked', true);
    fields.$employmentType.prop('checked', true);
    fields.$station.prop('checked', true);
    fields.$salaryHourlyBottom.val(this.hiddenValues.salaryHourlyBottom);
    fields.$salaryMonthlyBottom.val(this.hiddenValues.salaryMonthlyBottom);
  }

  clear = ({ $content }) => {
    const $checkboxes = $content.find('input:checkbox');
    const $selectboxes = $content.find('select');

    $checkboxes.prop('checked', false);
    $selectboxes.prop('selectedIndex', 0);
  }
}

export {
  RefineSearch,
};
