import $ from 'jquery';
import { Modal } from './modal';

class SearchStateModal {
  constructor({ modalId, onHide, onShow }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${this.modalId}"]`),
      onHide,
      onShow,
      isContentHeightDynamic: true,
      beforeShow: this.beforeShow,
    });
  }

  beforeShow = ({ trigger }) => {
    this.$trigger = $(trigger);
    this.jobCategoryCode = this.$trigger.data('job-category-code');

    const ddrModal = $('.js-search-state-modal_job-category-code-ddr');
    const dhModal = $('.js-search-state-modal_job-category-code-dh');
    const daModal = $('.js-search-state-modal_job-category-code-da');
    const dcModal = $('.js-search-state-modal_job-category-code-dc');

    // すべてのモーダルコンテンツを非表示にする
    ddrModal.hide();
    dhModal.hide();
    daModal.hide();
    dcModal.hide();

    // 指定された職種に応じて適切なモーダルコンテンツを表示する
    if (this.jobCategoryCode === 'ddr') {
      ddrModal.show();
    } else if (this.jobCategoryCode === 'dh') {
      dhModal.show();
    } else if (this.jobCategoryCode === 'da') {
      daModal.show();
    } else if (this.jobCategoryCode === 'dc') {
      dcModal.show();
    }
  }

  init = () => {
    this.modal.init();
  }
}

export {
  SearchStateModal,
};
