import $ from 'jquery';
import { ContentLoader } from './loader';

class AsyncJobOfferAggregation {
  constructor(wrapper, { storageButtonManager }) {
    this.$wrapper = $(wrapper);
    this.aggregationType = this.$wrapper.data('aggregation-type');
    this.jobOfferId = this.$wrapper.data('job-offer-id');
    this.recommendKey = this.$wrapper.data('recommend-key');
    this.refPage = this.$wrapper.data('ref-page');
    this.loader = this.$wrapper.data('loader');
    this.storageButtonManager = storageButtonManager;
  }

  getAPIUrl = () => {
    return `/api/users/job_offer_aggregations/v2/${ this.aggregationType }`;
  }

  render = async () => {
    this.insertLoadingIcon();

    let response;
    try {
      response = await $.ajax({
        type: 'GET',
        url: this.getAPIUrl(),
        data: {
          job_offer_id: this.jobOfferId,
          ref_page: this.refPage,
          recommend_key: this.recommendKey,
        },
      });
    } catch (err) {
      this.removeLoadingIcon();
      throw err;
    }
    if (!this.isResponseHtmlExist(response)) {
      this.removeLoadingIcon();
      return;
    }
    this.removeLoadingIcon();
    this.$wrapper.append(response);
    await this.syncStorageButton();
  }

  isResponseHtmlExist = (response) => {
    return (response && $(response).length > 0);
  }

  syncStorageButton = () => {
    this.storageButtonManager.sync();
  }

  insertLoadingIcon = () => {
    if (!this.loader) {
      return;
    }
    ContentLoader.show(this.$wrapper, this.loader);
  }

  removeLoadingIcon = () => {
    if (!this.loader) {
      return;
    }
    ContentLoader.hide(this.$wrapper);
  }
}

export {
  AsyncJobOfferAggregation,
};
