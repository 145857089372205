import $ from 'jquery';
import * as yup from 'yup';
import t from './util/i18n';
import { PATTERNS } from './constants/patterns';
import { emitter } from './util/event-emitter';
import { FootBar } from './foot-bar';
import { PreventMisapplicationModal } from './modules/modal';
import { FileAttachedConfirmationModal } from './modules/modal/file-attached-confirmation-modal';

const MESSAGE_TYPE_APPLICATION = '2';
const MESSAGE_TYPE_NORMAL = '7';
const MESSAGE_TYPE_REFUSE_SCOUT = '8';
const MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW = '16';

const INTERVIEW_METHOD_OFFLINE_INTERVIEW = '1';
const LIMIT_FILE_SIZE = 5 * 1024 * 1024;

class MessageForm {
  constructor({
    form,
    appliedNotificationModal,
    refusedScoutNotificationModal,
  }) {
    this.$form = $(form);
    this.appliedNotificationModal = appliedNotificationModal;
    this.refusedScoutNotificationModal = refusedScoutNotificationModal;
    this.preventMisapplicationModal = new PreventMisapplicationModal({ modalId: 'prevent-misapplication', $form: this.$form });
    this.fileAttachedConfirmationModal = new FileAttachedConfirmationModal(this.$form);
    this.footBar = new FootBar('#js-message-foot-bar', { lowerLandmark: '.js-message-foot-bar-lower-landmark' });

    this.$attachedResume = this.$form.find('.js-attached-resume');
    this.$attachedResumeDeleteButton = this.$attachedResume.find('.js-attached-resume-delete');
    this.$formAttachedFile = $('.js-attach-file-input');
    this.$formUploadResumeFile = $('.js-resume-file-input');
    this.$formAttachResumeId = $('.js-attach-resume-input');

    this.$replyTypesSelectBox = this.$form.find('.js-select-reply-type');
    this.$templateLink = this.$form.find('.js-use-template');
    this.$messageFormBody = this.$form.find('.js-message-form-body');
    this.$submitButton = this.$form.find('.js-form__submitter');

    this.$confirmSentence = this.$form.find('.js-confirm-sentence');

    this.$interviewLocaion = this.$form.find('.js-interview-location-group');
    this.$replyTypesLinkArea = this.$form.find('.js-reply-types-link-area');
  }

  init = (formManagers) => {
    // NOTE: init後のformManagerのインスタンスが必要のため、init関数でもらうようにしている.
    this.formManager = formManagers[0];
    this.appliedNotificationModal.init();
    this.refusedScoutNotificationModal.init();
    this.preventMisapplicationModal.init();
    this.fileAttachedConfirmationModal.init();
    this.footBar.init();

    this.$templateLink.on('click', (e) => {
      e.preventDefault();
      this.updateMessageFormBody();
    });
    this.$replyTypesSelectBox.on('change', this.changeMessageType);

    $('input[name="message[interview_method]"]:radio').on('change', this.switchInterviewMethod);
    // NOTE: 初期表示に、対面面接がチェックされている時に、 `対面面接の面接場所` を表示させるため
    const value = $('input[name="message[interview_method]"]:checked').val();
    if (value === INTERVIEW_METHOD_OFFLINE_INTERVIEW) {
      this.$interviewLocaion.removeClass('u-hidden');
    }

    $('.js-change-message-type-normal').on('click', { messageType: MESSAGE_TYPE_NORMAL }, this.changeMessageType);
    $('.js-change-message-type-reply').on('click', { messageType: MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW }, this.changeMessageType);
    emitter.on('formManager:submit', ({ $form }) => {
      if ($form.is(this.$form)) {
        const matchedKeyword = this.matchMisapplicationKeywords();
        if (!this.validFileLimit()) {
          return;
        }
        if (this.isApplication() && matchedKeyword) {
          $('.js-prevent-misapplication-keyword').text(matchedKeyword[0]);
          this.preventMisapplicationModal.show();
        } else if (this.isAttachedFile() && this.fileAttachedConfirmationModal.displayableModal()) {
          this.fileAttachedConfirmationModal.show();
        } else {
          this.$submitButton.prop('disabled', true);
          this.$form.off('submit').submit();
        }
      }
    });

    // NOTE: this.$replyTypesSelectBox の要素を取得できている時だけ処理を実行する
    if (this.$replyTypesSelectBox.length > 0) {
      // NOTE: プリセットされたメッセージタイプごとに初期表示処理を実行する
      this.processByMessageType(this.$replyTypesSelectBox.val());
      this.buildValidationByMessageType();
      this.formManager.setBeforeValidation(this.buildValidationByMessageType);
    }
  }

  addSchemaReplyArrangementInterview = () => {
    const preferredDatetimeElements = $('.js-reply_preferred-datetime__target')
      .find('input[name="message[reply_preferred_datetime][]"]');
    if (preferredDatetimeElements.length > 0) {
      this.formManager.addSchema('message[reply_preferred_datetime][]', {
        getValue: ($form) => {
          return $form.find('.js-form-manager__field--reply_preferred_datetime:checked').length;
        },
        validation: yup
          .string()
          .test('required', t('validations.message.interview_date_required'), (checkedLength) => {
            return checkedLength > 0;
          }),
      });
    }

    const inteviewMethodElements = $('.js-reply-interview-method__target')
      .find('input[name="message[interview_method]"]');
    if (inteviewMethodElements.length > 0) {
      this.formManager.addSchema('message[interview_method]', {
        getValue: ($form) => {
          const value = $form.find('[name="message[interview_method]"]:checked').val();
          if (value === undefined) {
            return 0;
          }
          return +value || 0;
        },
        validation: yup
          .string()
          .test('required', t('validations.message.interview_method_required'), (checkedLength) => {
            return checkedLength > 0;
          }),
      });
    }
  }

  buildValidationByMessageType = () => {
    const messageType = this.$replyTypesSelectBox.val();

    // TODO: 将来的にタイプ別のバリデーションが増えた時、
    //       タイプごとのスキーマビルド処理をリファクタしたほうが良い。
    switch (messageType) {
      case MESSAGE_TYPE_NORMAL:
        this.formManager.removeSchema('message[reply_preferred_datetime][]');
        this.formManager.removeSchema('message[interview_method]');
        $('.js-form-manager__fieldset--preferred-datetime').find('.js-form-manager__error--hook').removeClass('js-form-manager__error-msg');
        $('.js-form-manager__fieldset--interview-method').find('.js-form-manager__error--hook').removeClass('js-form-manager__error-msg');
        break;
      case MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW:
        this.addSchemaReplyArrangementInterview();
        $('.js-form-manager__fieldset--preferred-datetime').find('.js-form-manager__error--hook').addClass('js-form-manager__error-msg');
        $('.js-form-manager__fieldset--interview-method').find('.js-form-manager__error--hook').addClass('js-form-manager__error-msg');
        break;
      default:
        break;
    }
  }

  switchInterviewMethod = (e) => {
    const value = e.currentTarget.value;
    if (value === INTERVIEW_METHOD_OFFLINE_INTERVIEW) {
      this.$interviewLocaion.removeClass('u-hidden');
    } else {
      this.$interviewLocaion.addClass('u-hidden');
    }
  }

  // NOTE: メッセージタイプの切り替え時に、caseでタイプが被った場合は、
  //       case文の中でさらに分岐して判断するようにして拡張する
  changeMessageType = (e) => {
    let value = e.currentTarget.value;

    // NOTE: データがバインドされている場合は、プルダウンのonChange以外の
    //       イベントを経由しているので、以下の値を使用する
    if (e.data) {
      value = e.data.messageType;
      this.$replyTypesSelectBox.val(value);
    }
    this.processByMessageType(value);
  }

  processByMessageType = (value) => {
    switch (value) {
      case MESSAGE_TYPE_NORMAL:
        this.$replyTypesLinkArea.addClass('u-hidden');
        break;
      case MESSAGE_TYPE_REPLY_ARRANGEMENT_INTERVIEW:
        this.$replyTypesLinkArea.removeClass('u-hidden');
        break;
      case MESSAGE_TYPE_APPLICATION:
      case MESSAGE_TYPE_REFUSE_SCOUT:
        this.$replyTypesLinkArea.removeClass('u-hidden');
        this.syncTemplateLinkUI();
        break;
      default:
        break;
    }
  }

  updateMessageFormBody = () => {
    const template = this.$replyTypesSelectBox.find('option:selected').data('template');
    if (template) {
      this.$messageFormBody.val(template);
    } else {
      this.$messageFormBody.val('');
    }
  }

  syncTemplateLinkUI = () => {
    const $selectedOption = this.$replyTypesSelectBox.find('option:selected');
    const template = $selectedOption.data('template');
    const explanation = $selectedOption.data('explanation');

    if (template) {
      this.$templateLink.removeClass('u-hidden');
      this.$templateLink.text(explanation);
    } else {
      this.$templateLink.addClass('u-hidden');
      this.$templateLink.text('');
    }
  }

  matchMisapplicationKeywords = () => {
    return this.$messageFormBody.val().match(PATTERNS.MISAPPLICATION_KEYWORDS);
  }

  isApplication = () => {
    return this.$replyTypesSelectBox.find('option:selected').val() === MESSAGE_TYPE_APPLICATION;
  }

  isAttachedFile = () => {
    return !!this.$formAttachedFile.val() || !!this.$formUploadResumeFile.val() || !!this.$formAttachResumeId.val();
  }

  validFileLimit = () => {
    let valid = true;
    for (let i = 0; i < this.$formUploadResumeFile[0].files.length; i += 1) {
      if (this.$formUploadResumeFile[0].files[i].size >= LIMIT_FILE_SIZE) {
        $('.js-message-attach-file-size-limit').removeClass('u-hidden');
        valid = false;
      }
    }
    for (let i = 0; i < this.$formAttachedFile[0].files.length; i += 1) {
      if (!valid) {
        break;
      }
      if (this.$formAttachedFile[0].files[i].size >= LIMIT_FILE_SIZE) {
        $('.js-message-attach-file-size-limit').removeClass('u-hidden');
        valid = false;
      }
    }
    if (this.$formAttachedFile[0].files.length > 5) {
      $('.js-message-attach-file-count-limit').removeClass('u-hidden');
      valid = false;
    }
    return valid;
  }
}

export {
  MessageForm,
};
