import * as yup from 'yup';
import $ from 'jquery';
import t from '../util/i18n';
import { PATTERNS } from '../constants/patterns';
import { toHankaku } from '../util/string';
import { memberSchema } from './member-schema';

const isEmailAlreadyRegistered = async (value) => {
  if (!value) {
    return true;
  }
  const response = await $.ajax({
    type: 'GET',
    url: '/api/users/members/exist_email',
    data: {
      'member[email]': value,
    },
  });
  if (response.data.status) {
    return false;
  }
  return true;
};

const hasBannedWords = (value) => {
  if (value.isDisabled) {
    return true;
  }

  const body = value.body;
  const converted = toHankaku(body);
  let res = [];

  if (converted) {
    res = (converted.match(PATTERNS.BANNED_WORDS) || []);
  }

  return !(res.length > 0);
};

const notAllowBlank = (value) => {
  return value.trim() !== '';
};

const newMemberSchema = {
  'member[email]': {
    validation: yup
      .string()
      .label(t('validations.email.label'))
      .required()
      .max(100)
      .matches(PATTERNS.MAIL_ADDRESS, t('validations.email.format'))
      .when('$eventType', (eventTypeName, schema) => {
        return eventTypeName === 'submit'
          ? schema.test('is-already-registered-email', t('validations.email.duplication'), isEmailAlreadyRegistered)
          : schema;
      }),
    count: true,
  },

  'member[password]': {
    validation: yup
      .string()
      .label(t('validations.password.label'))
      .required()
      .min(8),
    count: true,
  },

};

const schema = {
  login: {
    'users2016_member[email]': {
      validation: yup
        .string()
        .label(t('validations.email.label'))
        .required()
        .matches(PATTERNS.MAIL_ADDRESS, t('validations.email.format_short')),
    },

    'users2016_member[password]': {
      validation: yup
        .string()
        .label(t('validations.password.label'))
        .required(),
    },
  },

  'search-condition': {
    title: {
      validation: yup
        .string()
        .label(t('validations.search_condition.label'))
        .required()
        .test('not-allow-blank', t('validations.search_condition.not_allow_blank'), notAllowBlank)
        .max(100),
    },
  },

  registration: {
    ...memberSchema,
    ...newMemberSchema,

    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .label(t('validations.desired_employment_type.label'))
        .test('required', t('validations.desired_employment_type.required'), (checkedLength) => {
          return checkedLength > 0;
        }),
      count: true,
    },

    'member[job_category_ids][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-job-category-id:checked').length;
      },
      validation: yup
        .string()
        .label(t('validations.desired_job_category.label'))
        .test('required', t('validations.desired_job_category.required'), (checkedLength) => {
          return checkedLength > 0;
        }),
      count: true,
    },
  },

  application: {
    ...memberSchema,
    ...newMemberSchema,

    jobOfferSalary: {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--job-offer-salary').filter(':checked').length;
      },
      getDestination: $form => $form.find('.js-form-manager__field--job-offer-salary'),
      validation: yup
        .number()
        .label(t('validations.application.employment_type.label'))
        .min(1),
      count: true,
    },

    'member[us_work_authorization]': {
      getValue: ($form) => {
        const value = $form.find('[name="member[us_work_authorization]"]:checked').val();
        if (value === undefined) {
          return 0;
        }

        return value;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[us_work_authorization]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .number()
        .min(1, t('validations.application.us_work_authorization.required')),
      count: true,
    },

    'member[member_resume_id]': {
      validation: yup
        .string()
        .label(t('validations.member_resume_id.label')),
    },

    'member[upload_resume]': {
      getValue: ($form) => {
        return {
          isRequired: $form.find('input[name="member[upload_resume]"]').prop('required'),
          file: $form.find('input[name="member[upload_resume]"]').val(),
          memberResumeId: $form.find('input[name="message[member_resume_id]"]').val(),
        };
      },
      validation: yup
        .mixed()
        .test('is-required', t('validations.application.upload_resume.required'), (value) => {
          // 応募がレジュメ必須で、レジュメがアップロードされていない / レジュメが選択されていない場合は、falseでエラーを出す
          if (value.isRequired === true && !value.memberResumeId && value.file === '') {
            return false;
          }
          return true;
        }),
      count: (value) => {
        return value.isRequired;
      },
    },

    'member[qualification_state]': {
      getValue: ($form) => {
        return {
          isRequired: $form.find('[name="member[qualification_state]"]').length > 0,
          value: $form.find('[name="member[qualification_state]"]:checked').val(),
        };
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[qualification_state]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .mixed()
        .test('is-required', t('validations.application.qualification_state.required'), (value) => {
          return value.isRequired === false || (value.value === 'true' || value.value === 'false');
        }),
      count: (value) => {
        return value.isRequired;
      },
    },
  },

  'password-reset': {
    'users2016_member[email]': {
      validation: yup
        .string()
        .label(t('validations.email.label'))
        .required()
        .matches(PATTERNS.MAIL_ADDRESS, t('validations.email.format_short')),
    },
  },
  'password-renew': {
    'users2016_member[password]': {
      validation: yup
        .string()
        .label(t('validations.password.label'))
        .required()
        .min(8),
    },
    'users2016_member[password_confirmation]': {
      getValue: ($form) => {
        return {
          password: $form.find('input[name="users2016_member[password]"]').val(),
          passwordConfirm: $form.find('input[name="users2016_member[password_confirmation]"]').val(),
        };
      },
      validation: yup
          .object({
            passwordConfirm: yup
              .string()
              .label(t('validations.password.label'))
              .required()
              .min(8),
          })
          .test('is-not-equal', t('validations.password.different'), (value) => {
            return value.password === value.passwordConfirm;
          }),
    },
  },
  message: {
    'message[body]': {
      getValue: ($form) => {
        return {
          isDisabled: $form.find('textarea[name="message[body]"]').data('disable-banned-words'),
          body: $form.find('textarea[name="message[body]"]').val(),
          resumeFile: $form.find('input[name="message[resume_file]"]').val(),
          memberResumeId: $form.find('input[name="message[member_resume_id]"]').val(),
          messageFiles: $form.find('input[name="message[message_files][]"]').val(),
        };
      },
      validation: yup
        .object({
          body: yup
            .string()
            .label(t('validations.message.body.label')),
        })
        .test('is-required', t('validations.message.body.required'), (value) => {
          return value.body || value.resumeFile || value.messageFiles || value.memberResumeId;
        })
        .test('has-banned-words', t('validations.message.body.ng_word'), hasBannedWords),
    },
  },
  inquiry: {
    'inquiry[family_name]': {
      validation: yup
        .string()
        .label(t('validations.inquiry.family_name.label'))
        .trim()
        .required()
        .max(50),
    },

    'inquiry[first_name]': {
      validation: yup
        .string()
        .label(t('validations.inquiry.first_name.label'))
        .trim()
        .required()
        .max(50),
    },

    'inquiry[corporation_name]': {
      validation: yup
        .string()
        .label(t('validations.inquiry.corporation_name.label'))
        .max(100),
    },

    'inquiry[tel]': {
      validation: yup
        .string()
        .transform(v => (!v ? undefined : v))
        .matches(PATTERNS.TEL, t('validations.inquiry.tel.format')),
    },

    'inquiry[email]': {
      validation: yup
        .string()
        .label(t('validations.email.label'))
        .required()
        .max(100)
        .matches(PATTERNS.MAIL_ADDRESS, t('validations.email.format')),
    },

    'inquiry[body]': {
      validation: yup
        .string()
        .trim()
        .label(t('validations.inquiry.body.label'))
        .required()
        .max(4000),
    },

    'inquiry[agree]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--agree:checked').length;
      },
      validation: yup
        .string()
        .test('required', t('validations.agree.required'), (checkedLength) => {
          return checkedLength > 0;
        }),
    },
  },
  landingPageGeneralStep: {
    ...memberSchema,
    ...newMemberSchema,
    'member[member_desired_employment_types_attributes][][employment_type]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--employment-type:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[member_desired_employment_types_attributes][][employment_type]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .string()
        .label(t('validations.desired_employment_type.label'))
        .test('required', t('validations.desired_employment_type.required'), (checkedLength) => {
          return checkedLength > 0;
        }),
    },

    'member[correct_job_category]': {
      getValue: ($form) => {
        return {
          isRequired: $form.find('[name="member[correct_job_category]"]').length > 0,
          value: $form.find('[name="member[correct_job_category]"]:checked').val(),
        };
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="member[correct_job_category]"]').closest('.js-form-manager__fieldset').find('label');
      },
      validation: yup
        .mixed()
        .test('is-required', t('validations.landing_page.correct_job_category.required'), (value) => {
          return value.isRequired === false || value.value === 'true';
        }),
    },
  },
  celebration: {
    'user_voice[answers_attributes][0][body][]': {
      getValue: ($form) => {
        return $form.find('.js-form-manager__field-user-voice-answer:checked').length;
      },
      getStyleTargetElement: ($form) => {
        return $form.find('[name="user_voice[answers_attributes][0][body][]"]').siblings('label');
      },
      validation: yup
        .string()
        .test('required', t('validations.celebration.user_voice.answer.required'), (checkedLength) => {
          return checkedLength > 0;
        }),
    },
    'user_voice[hiring_date(1i)]': {
      validation: yup
        .string()
        .label(t('validations.celebration.user_voice.hiring_date.label'))
        .required(),
    },
    'user_voice[hiring_date(2i)]': {
      validation: yup
        .string()
        .label(t('validations.celebration.user_voice.hiring_date.label'))
        .required(),
    },
    'user_voice[hiring_date(3i)]': {
      getValue: ($form) => {
        const $hiringDates = $form.find('.js-form-manager__field--hiring-date');
        return {
          hiringDates: [...$hiringDates].map(field => field.value),
        };
      },
      getDestination: $form => $form.find('.js-form-manager__field--hiring-date'),
      validation: yup
        .object()
        .test('is-valid-hiring-date', t('validations.celebration.user_voice.hiring_date.valid'), (value) => {
          const {
            hiringDates,
          } = value;
          const y = hiringDates[0];
          const m = hiringDates[1];
          const d = hiringDates[2];

          if (!y || !m || !d) {
            return false;
          }
          return true;
        }),
    },
    // 現状ロジックから100文字のパターンのみしか無いのでそのパターンのみ作る
    user_voice_min_free_text: {
      getStyleTargetElement: ($form) => {
        return $form.find('.js-form-manager__field--user-voice-free-text');
      },
      getDestination: $form => $form.find('.js-form-manager__field--user-voice-free-text'),
      getValue: ($form) => {
        return $form.find('.js-form-manager__field--user-voice-free-text').val();
      },
      validation: yup
        .string()
        .label(t('validations.celebration.user_voice.free_text.label'))
        .trim()
        .required()
        .test('min words', 'Please enter at least 30 words.', (value) => {
          return value.split(/\s+/).length >= 30;
        }),
    },
    'user_voice[rating]': {
      validation: yup
        .string()
        .label(t('validations.celebration.user_voice.rating.label'))
        .required(),
    },
    jobley_bonus_agreement: {
      getValue: ($form) => {
        const isChecked = $form.find('.js-form-manager__field--jobley-bonus-agreement:checked').length > 0;
        return isChecked;
      },
      getDestination: $form => $form.find('.js-form-manager__field--jobley-bonus-agreement'),
      validation: yup
        .boolean()
        .label('check')
        .required('Please complete this required field.')
        .oneOf([true], 'Please complete this required field.'),
    },
  },
  'settings-notification': {},
  'settings-support': {},
  'settings-email': {
    'member[email]': {
      validation: yup
        .string()
        .label(t('validations.email.label'))
        .required()
        .matches(PATTERNS.MAIL_ADDRESS, t('validations.email.format'))
        .max(100)
        .test('is-already-registered-email', t('validations.email.duplication'), isEmailAlreadyRegistered),
    },
  },
  'settings-password': {
    'member[current_password]': {
      validation: yup
        .string()
        .label(t('validations.current_password.label'))
        .required(),
    },
    'member[password]': {
      validation: yup
        .string()
        .label(t('validations.password.label'))
        .required()
        .min(8),
    },
    'member[password_confirmation]': {
      getValue: ($form) => {
        return {
          password: $form.find('input[name="member[password]"]').val(),
          passwordConfirm: $form.find('input[name="member[password_confirmation]"]').val(),
        };
      },
      validation: yup
        .object({
          passwordConfirm: yup
            .string()
            .label(t('validations.password.label'))
            .required()
            .min(8),
        })
        .test('is-not-equal', t('validations.password.different'), (value) => {
          return value.password === value.passwordConfirm;
        }),
    },
  },
  'profile-basic': async () => {
    const { ProfileBasicSchema } = await import('./profile-basic-schema');
    return new ProfileBasicSchema().buildSchema();
  },
  'profile-education-background': async () => {
    const { ProfileEducationBackgroundSchema } = await import('./profile-education-background-schema');
    return new ProfileEducationBackgroundSchema().buildSchema();
  },
  'profile-career': async () => {
    const { ProfileCareerSchema } = await import('./profile-career-schema');
    return new ProfileCareerSchema().buildSchema();
  },
  'profile-desire': async () => {
    const { ProfileDesireSchema } = await import('./profile-desire-schema');
    return new ProfileDesireSchema().buildSchema();
  },
  'profile-additional-member-qualification': async () => {
    const { ProfileAdditionalMemberQualificationSchema } = await import('./profile-additional-member-qualification-schema');
    return new ProfileAdditionalMemberQualificationSchema().buildSchema();
  },
  'profile-additional-desired-job-category': async () => {
    const { ProfileAdditionalDesiredJobCategorySchema } = await import('./profile-additional-desired-job-category-schema');
    return new ProfileAdditionalDesiredJobCategorySchema().buildSchema();
  },
  'resume-item': async () => {
    const { ResumeItemSchema } = await import('./resume-item-schema');
    return new ResumeItemSchema().buildSchema();
  },
  'member-careersheet': async () => {
    const { MemberCareersheetSchema } = await import('./member-careersheet-schema');
    return new MemberCareersheetSchema().buildSchema();
  },
};

export {
  schema,
};
