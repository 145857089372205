import $ from 'jquery';
import t from './util/i18n';

class Favorite {
  constructor({ shouldAttachEvent = true } = {}) {
    this.shouldAttachEvent = shouldAttachEvent;
  }

  $target = null

  $targetIcon = null

  $targetText = null

  savedClassName = 'c-button__keep-icon--hold'

  triggerClassName = 'js-favorite-button'

  counterClassName = 'js-favorite-counter'

  // key: cardIds.join(','), value: response
  cachedSyncResponse = {}

  currentPageJobOfferId = null

  init = () => {
    this.sync();

    if (this.shouldAttachEvent) {
      this.attachEvent();
    }

    this.setCurrentPageJobOfferId();
  }

  setCurrentPageJobOfferId = () => {
    const pageViewID = $('.js-page-view-id').data('page-view-id');
    if (pageViewID !== 'job_offers/detail') {
      return;
    }

    const currentPageJobOfferId = $('.js-favorite-job-offer').data('current-page-job-offer-id');
    if (currentPageJobOfferId === undefined) {
      return;
    }

    this.currentPageJobOfferId = currentPageJobOfferId;
  }

  attachEvent = () => {
    $(document.body).on('click', '.js-favorite-button', (e) => {
      e.preventDefault();
      this.update(e.currentTarget);
    });
  }

  isSavedJobOffer = ($target) => {
    return $target.hasClass(this.savedClassName);
  }

  update = (target) => {
    this.$target = $(target);
    this.$targetIcon = this.$target.find('.js-favorite-icon');
    this.$targetText = this.$target.find('.js-favorite-text');
    this.jobOfferId = this.$target.data('job-offer-id');

    if (this.isSavedJobOffer(this.$targetIcon)) {
      return this.updateToUnfavorite();
    }
    return this.updateToFavorite();
  }

  hasResponseMessage = (response) => {
    return response && response.responseJSON && response.responseJSON.message;
  }

  updateToFavorite = async () => {
    let response = null;

    try {
      response = await $.ajax({
        type: 'POST',
        url: '/api/users/favorite_job_offers',
        data: {
          job_offer_id: this.$target.data('job-offer-id'),
        },
      });
    } catch (error) {
      if (this.hasResponseMessage(error)) {
        /* eslint-disable no-alert */
        alert(error.responseJSON.message);
      } else {
        alert(t('favorite.failed_add_favorite'));
        /* eslint-enable no-alert */
      }
      throw new Error(error);
    }
    const favoriteButtons = [...document.querySelectorAll('.js-favorite-button')].filter((button) => {
      const jobOfferId = $(button).data('job-offer-id');
      return `${ this.jobOfferId }` === (`${ jobOfferId }`);
    });
    await favoriteButtons.forEach(async (currentButton) => {
      const $currentIcon = $(currentButton).find('.js-favorite-icon');
      const $currentText = $(currentButton).find('.js-favorite-text');
      await this.updateButtonToFavorite($currentIcon, $currentText);
    });
    await this.count(response.count);
    this.updateUnpublishedText(response.data);
  }

  updateToUnfavorite = async () => {
    let response = null;

    try {
      response = await $.ajax({
        type: 'DELETE',
        url: '/api/users/favorite_job_offers',
        data: {
          job_offer_id: this.$target.data('job-offer-id'),
        },
      });
    } catch (error) {
      if (this.hasResponseMessage(error)) {
        /* eslint-disable no-alert */
        alert(response.responseJSON.message);
      } else {
        alert(t('favorite.failed_remove_favorite'));
        /* eslint-enable no-alert */
      }
      throw new Error(error);
    }
    const favoriteButtons = [...document.querySelectorAll('.js-favorite-button')].filter((button) => {
      const jobOfferId = $(button).data('job-offer-id');
      return `${ this.jobOfferId }` === (`${ jobOfferId }`);
    });
    await favoriteButtons.forEach(async (currentButton) => {
      const $currentIcon = $(currentButton).find('.js-favorite-icon');
      const $currentText = $(currentButton).find('.js-favorite-text');
      await this.updateButtonToUnfavorite($currentIcon, $currentText);
    });
    await this.count(response.count);
    this.updateUnpublishedText(response.data);
  }

  count = (count) => {
    $(`.${ this.counterClassName }`).text(count > 0 ? count : '');
  }

  sync = async () => {
    const jobOfferIds = [...document.querySelectorAll('.js-favorite-button')].map(current => $(current).data('job-offer-id'));

    if (jobOfferIds.length < 1) {
      return;
    }
    const key = jobOfferIds.join(',');
    let response = null;
    if (this.cachedSyncResponse[key]) {
      response = this.cachedSyncResponse[key];
    } else {
      response = await $.ajax({
        type: 'GET',
        url: '/api/users/favorite_job_offers/status/',
        data: {
          job_offer_id: jobOfferIds,
        },
      });
      this.cachedSyncResponse[key] = response;
    }

    if (!response || !response.length) {
      return;
    }
    const savedIds = await response.map(res => `${ res.job_offer_id }`);
    const favoriteButtons = await [...document.querySelectorAll('.js-favorite-button')].filter((current) => {
      const jobOfferId = $(current).data('job-offer-id');
      return savedIds.includes(`${ jobOfferId }`);
    });
    favoriteButtons.forEach((currentButton) => {
      const $currentIcon = $(currentButton).find('.js-favorite-icon');
      const $currentText = $(currentButton).find('.js-favorite-text');
      this.updateButtonToFavorite($currentIcon, $currentText);
    });
  }

  updateButtonToFavorite = ($targetIcon, $targetText) => {
    $targetIcon.addClass(this.savedClassName);
    $targetText.text(t('favorite.favorited'));
  }

  updateButtonToUnfavorite = ($targetIcon, $targetText) => {
    $targetIcon.removeClass(this.savedClassName);
    $targetText.text(t('favorite.favorite'));
  }

  /**
   * 募集終了求人時の赤枠内テキストの文言を変更する
   * 「気になる」登録、「気になる」解除の時のみ実行
   * 「気になる」ボタンの文言と違い、初回描画時 =sync() からの呼び出しはしない
   * cf. app/view_objects/user_view_objects/favorite_job_offers/unpublished_text.rb
   * cf. https://github.com/medley-inc/job-medley/pull/17278#discussion_r454846364
   * @param responseData response.data
   */
  updateUnpublishedText = (responseData) => {
    // null になるのは求人詳細ページでない場合
    if (this.currentPageJobOfferId === null) {
      return;
    }
    // this.jobOfferId = 押下した「気になる」ボタンの data-job-offer-id
    // 求人詳細ページ内に存在する別求人の「気になる」を押下した場合、ここで return
    if (this.currentPageJobOfferId !== this.jobOfferId) {
      return;
    }
    if (!this.isUnpublishedJobOffer(responseData)) {
      return;
    }

    $('.js-unpublished-text').first().html(responseData.unpublished_text);
  }

  /**
   * API 側で募集終了求人に対する操作の時だけ `unpublished_text` を response.data に含めている
   * @param responseData response.data
   * @return boolean
   */
  isUnpublishedJobOffer = (responseData) => {
    return 'unpublished_text' in responseData;
  }
}

export {
  Favorite,
};
