import $ from 'jquery';
import { Modal } from './modal';
import t from '../../util/i18n';

class HideMessageModal {
  constructor({ modalId }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow: this.beforeShow,
    });
  }

  beforeShow = ({ trigger }) => {
    this.$trigger = $(trigger);
    this.jobOfferId = this.$trigger.data('job-offer-id');
  }

  init = () => {
    this.modal.init();
    this.$submitButton = this.modal.$wrapper.find('.js-modal__submitter');
    this.$submitButton.on('click', (e) => {
      e.preventDefault();
      this.hideMessage();
    });
  }

  hideMessage = async () => {
    try {
      await $.ajax({
        type: this.getAPIUrlMethod(),
        url: this.getAPIUrl(),
        data: {
          job_offer_id: this.jobOfferId,
        },
      });
    } catch (error) {
      /* eslint-disable no-alert */
      alert(this.getAlertText());
      /* eslint-enable no-alert */
      throw new Error(error);
    }
    window.location.reload();
  }

  getAPIUrl = () => {
    const baseUrl = '/api/users/messages/';
    if (this.modalId === 'hide-message') {
      return `${ baseUrl }change_to_hidden/`;
    }
    return `${ baseUrl }change_to_un_hidden/`;
  }

  getAPIUrlMethod = () => {
    if (this.modalId === 'hide-message') {
      return 'POST';
    }
    return 'DELETE';
  }

  getAlertText = () => {
    if (this.modalId === 'hide-message') {
      return t('modals.hide_failed');
    }
    return t('modals.show_failed');
  }
}

export {
  HideMessageModal,
};
