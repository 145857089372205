import Common from './common.json';
import Validations from './validations.json';
import Modals from './modals.json';
import Favorite from './favorite.json';
import Keep from './keep.json';
import Search from './search.json';
import CheckboxSynchronizer from './checkbox_synchronizer.json';
import RestartGuidanceManager from './restart_guidance_manager.json';

const Data = {};
Data.common = Common;
Data.validations = Validations;
Data.modals = Modals;
Data.favorite = Favorite;
Data.keep = Keep;
Data.search = Search;
Data.checkbox_synchronizer = CheckboxSynchronizer;
Data.restart_guidance_manager = RestartGuidanceManager;

export default Data;
