import i18next from 'i18next';
import enJson from '../locales/en';

i18next.init({
  lng: 'en',
  resources: {
    en: { translation: enJson },
  },
});

export default i18next.t;
