
// job_category_id: [
//    {
//      qualification_id: 'job_category_name'
//    }
//
// sync with JobCategoriesQualification::MUST_QUALIFICATION_MAPからREQUIRED_VALID_QUALIFICATION_MAPを除いたもの

const JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP = {
  3: [
    {
      1: 'Licensed practical nurses / Licensed vocational nurses',
    },
  ],
  4: [
    {
      2: 'Registered nurse',
    },
  ],
  13: [
    {
      34: 'Physician / Physician assistant',
    },
  ],
};

export {
  JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP,
};
