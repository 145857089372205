import $ from 'jquery';
import { Modal } from './modal';

class ApplyJobOfferDetailModal {
  constructor({
    modalId,
  }) {
    this.modalId = modalId;
    this.modal = new Modal({
      el: $(`.js-modal[data-modal-id="${ this.modalId }"]`),
      beforeShow: this.beforeShow,
    });
    /** @type {JQuery<HTMLLinkElement>} */
    this.$button = this.modal.$wrapper.find('.js-profile-celebration-link');
    this.$content = this.modal.$wrapper.find('.js-job-offer-content');
  }

  /**
   * @param {Object} arg
   * @param {HTMLElement} arg.trigger
   */
  beforeShow = async ({
    trigger,
  }) => {
    this.$content.empty();

    const applicationId = $(trigger).data('application-id');
    const response = await $.ajax({
      type: 'GET',
      url: `/api/users/apply_job_offers/job_offer/v2/${applicationId}`,
    });

    this.$content.html(response);
    if (this.$button.length > 0) {
      this.$button.attr('href', `/members/celebrations/new/${applicationId}/`);
    }
  }

  init = () => {
    this.modal.init();
  }
}

export {
  ApplyJobOfferDetailModal,
};
