import $ from 'jquery';
import { Modal } from './modal';

class RegisterModalBeforeExternalApplication {
  signUpBasePath = '/members/sign_up/';

  signUpPath: string;

  constructor() {
    this.signUpPath = this.signUpBasePath;
  }

  init = () => {
    const modalElement = $('.js-register-before-external-link');

    if (modalElement.length) {
      const modal = new Modal({ el: modalElement }).init();
      $(document.body).on('click', '.js-register-modal-before-external-application', (e) => {
        e.preventDefault();
        const target = e.target.closest('.js-register-modal-before-external-application');
        const externalType = target.dataset.externalType;
        const externalId = target.dataset.externalId;
        const externalUrl = target.dataset.externalUrl;
        this.resetSignUpPath(externalType, externalId);
        this.resetExternalLink(externalUrl);
        modal.show({});
      });
      $(document.body).on('click', '.js-register-button-before-external-application', (e) => {
        if (e.target.dataset.isDynamicLink === 'false') {
          return;
        }
        e.preventDefault();
        this.moveSignUp();
      });
    }
  }

  resetSignUpPath = (externalType: string, externalId: string) => {
    this.signUpPath = `${this.signUpBasePath}?external_type=${externalType}&external_id=${externalId}`;
  }

  resetExternalLink = (externalUrl: string) => {
    $('.js-external-link').attr('href', externalUrl);
  }

  moveSignUp = () => {
    window.location.href = this.signUpPath;
  }
}

export {
  RegisterModalBeforeExternalApplication,
};
