const PATTERNS = {
  POSTAL_CODE: /^\d{5}$/,
  MAIL_ADDRESS: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/,
  HIRAGANA: /^(?:[ぁ-ゞ]+)*$/,
  ADDRESS_HIRAGANA: /^(?:[ぁ-ゞ]|[０-９]|[−‐－ー―-]|[0-9]+)*$/,
  TEL: /^[2-9]{1}?[0-9]{2}[-]?([0-9]{3})[-]?([0-9]{4})$/,
  BANNED_WORDS: /(\d{10,13})|(\d{2,4}\-\d{2,4}\-\d{2,4})|(\d{2,4}\-\d{8})|(http)|(.+@.+\.)/, // eslint-disable-line
  MISAPPLICATION_KEYWORDS: /辞退|お断り|遠慮/,
};

export {
  PATTERNS,
};
