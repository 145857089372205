import Cookie from 'js-cookie';
import { isSecure } from './util/cookie';

class Timezone {
  constructor() {
    this.init();
  }

  init = () => {
    const now = new Date();
    const cookieOffset = this.currentTimezoneOffset();
    const jsOffset = now.getTimezoneOffset() * -1;
    if (!cookieOffset || jsOffset !== cookieOffset) {
      // NOTE: 2024/07/11にRails側ではOffsetを使わなくしたが、一旦残しておく
      //       リリース後特に問題起きなければ消して良さそう
      this.setOffsetCookie(jsOffset);
    }
    if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function' && typeof Intl.DateTimeFormat().resolvedOptions === 'function') {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timeZone) {
        this.setTimezoneCookie(timeZone);
      }
    }
  };

  currentTimezoneOffset = () => {
    const cookieOffset = Cookie.get('tz_offset');
    if (cookieOffset) {
      return Number(cookieOffset);
    }
    return null;
  };

  setTimezoneCookie = (timezone) => {
    Cookie.set('tz_name', timezone, { expires: 365, secure: isSecure() });
  }

  setOffsetCookie = (offset) => {
    Cookie.set('tz_offset', offset, { expires: 365, secure: isSecure() });
  }
}
export { Timezone };
