import $ from 'jquery';

class LinkExternalExtender {
  init = () => {
    $('.js-wrapper').on('click', '.js-link-external-extender', (e) => {
      this.linkTo(e);
    });
  }

  linkTo = (e: any) => {
    const havingLinkDom = e.target.closest('.js-link-external-extender__link');
    const externalUrl = havingLinkDom.dataset.externalUrl;

    if (externalUrl) {
      window.open(externalUrl, '_blank');
    }
    e.preventDefault();
  }
}

export {
  LinkExternalExtender,
};
