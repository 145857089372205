import * as yup from 'yup';
import $ from 'jquery';
import { PATTERNS } from '../constants/patterns';

const POSTAL_CODE_VALID_LENGTH = 5;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TestFunction = (this: yup.TestContext, value: any) => boolean | yup.ValidationError | Promise<boolean | yup.ValidationError>;

export const isPostalCode: TestFunction = (value) => {
  if (!value) {
    return true;
  }
  if (value.length !== POSTAL_CODE_VALID_LENGTH) {
    return false;
  }
  return value.match(PATTERNS.POSTAL_CODE);
};

export const isPostalCodeExist: TestFunction = async (value) => {
  if (!value) {
    return true;
  }
  if (value.length !== POSTAL_CODE_VALID_LENGTH) {
    return false;
  }
  const response = await $.ajax({
    type: 'GET',
    url: '/api/towns',
    data: {
      postal_code: value,
    },
  });

  if (!response.towns.length) {
    return false;
  }
  return true;
};
