import $ from 'jquery';
import { JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP } from './constants/required-qualification';
import { removeDuplicated, flatten } from './util/array';
import t from './util/i18n';

class RequiredQualificationByJobCategory {
  constructor(wrapper) {
    this.$wrapper = $(wrapper);
    this.$checkboxes = this.$wrapper.find('.js-required-qualification-by-job-category__checkbox');
    this.$errorMessage = this.$wrapper.find('.js-required-qualification-by-job-category__error-msg');
  }

  init = () => {
    this.update();
    this.$wrapper.on('change', '.js-required-qualification-by-job-category__checkbox', this.update);
  }

  update = () => {
    const text = this.isValid() ? '' : t('validations.required_qualification_by_job_category');

    this.$errorMessage.text(text);
  }

  createCompared = () => {
    const jobCategoryId = this.$checkboxes.eq(0).data('required-qualification-job-category-id');
    const targetJobCategoryRequiredQualificationMap = JOB_CATEGORY_REQUIRED_QUALIFICATION_MAP[jobCategoryId];

    if (!targetJobCategoryRequiredQualificationMap) {
      return {
        requiredQualificationIds: [],
        checkedQualificationIds: [],
      };
    }

    const requiredQualificationIds = removeDuplicated(
      flatten(
        targetJobCategoryRequiredQualificationMap.map(v => Object.keys(v).sort()),
      ),
    );
    const checkedQualificationIds = removeDuplicated(
      [
        ...(this.$checkboxes.filter(':checked')),
      ].map(v => v.value).sort(),
    );

    return {
      requiredQualificationIds,
      checkedQualificationIds,
    };
  }

  isValid = () => {
    const { requiredQualificationIds, checkedQualificationIds } = this.createCompared();
    const filteredCheckedIds = checkedQualificationIds.filter(id => requiredQualificationIds.includes(id));

    if (requiredQualificationIds.length < 1) {
      return true;
    }

    return filteredCheckedIds.some(id => requiredQualificationIds.includes(id));
  }
}

export {
  RequiredQualificationByJobCategory,
};
