import $ from 'jquery';
import Cookie from 'js-cookie';
import { Modal } from 'js/modules/modal';

class FileAttachedConfirmationModal {
  modal: Modal;

  $submitButton: JQuery<HTMLElement>;

  $messageForm: JQuery<HTMLElement>;

  $notAgainCheck: JQuery<HTMLElement>;

  constructor(form: JQuery<HTMLElement>) {
    this.modal = new Modal({ el: $('.js-modal[data-modal-id="file-attached-confirmation"]') });
    this.$messageForm = form;
    this.$submitButton = this.modal.$wrapper.find('.js-modal__submitter');
    this.$notAgainCheck = $('.js-modal__not-again-check');
  }

  init = () => {
    this.modal.init();
    this.$submitButton.on('click', this.submit);
  }

  show = () => {
    this.modal.show({});
  }

  submit = () => {
    if (this.$notAgainCheck.is(':checked')) {
      Cookie.set('hide_file_attach_confirmation', 'true', { expires: 30 });
    }
    this.$submitButton.prop('disabled', true);
    this.$messageForm.off('submit').submit();
  }

  displayableModal = () => {
    return !Cookie.get('hide_file_attach_confirmation');
  }
}

export {
  FileAttachedConfirmationModal,
};
