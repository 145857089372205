import $ from 'jquery';
import { emitter } from './util/event-emitter';
import Cookie from 'js-cookie';
import queryString from 'query-string';
import { SearchConditionSaveFormModal } from './modules/modal';

class SearchConditionSaveForm {
  constructor(form, { notificationModal }) {
    this.$form = $(form);
    this.$hiddenFields = $('.js-refine-search-hidden').find('input[type="hidden"]');
    this.$submit = this.$form.find('.js-search-condition-save-form-submit');
    this.notificationModal = notificationModal;
    const searchConditionSaveFormIds = ['search-condition-save-form', 'signin-or-signup'];
    const modalIds = $('.js-modal').map((_, el) => {
      return $(el).data('modal-id');
    }).get();
    this.modal = new SearchConditionSaveFormModal({
      modalId: searchConditionSaveFormIds.filter(searchConditionSaveFormId => modalIds.includes(searchConditionSaveFormId))[0],
    });
  }

  init = () => {
    this.modal.init();
    this.notificationModal.init();
    emitter.on('formManager:submit', ({ $form }) => {
      if ($form.is(this.$form)) {
        this.save();
      }
    });
    this.autoSaveSearchConditionWithSignup();
    this.showSaveSearchConditionModalWithSignin();
  }

  save = async () => {
    let response = null;
    const title = this.$form.find('input[name="title"]').val();
    const order = this.getOrderQuery() ? `&order=${ this.getOrderQuery() }` : '';
    const distance = this.getDistanceQuery() ? `&distance=${ this.getDistanceQuery() }` : '';
    const utf8 = this.getUtf8() ? `&utf8=${ this.getUtf8() }` : '';

    if (this.$submit.prop('disabled')) {
      return;
    }
    try {
      this.updateSubmitStatus(true);

      response = await $.ajax({
        type: 'POST',
        url: `/api/users/member_search_conditions?title=${ encodeURIComponent(title) }`,
        data: this.removeEmptyParameters(`${ this.$hiddenFields.serialize() }${ order }${ distance }${ utf8 }`),
      });

      if (response.data) {
        const in5minutes = new Date(new Date().getTime() + (5 * 60 * 1000));
        Cookie.set('modal_id', 'search-condition-saved', {
          expires: in5minutes,
          path: '/',
        });
        window.location.reload();
      }
    } catch (error) {
      this.updateSubmitStatus(false);
      throw new Error(error);
    }
  }

  removeEmptyParameters = (serialized) => {
    const queryStringList = serialized.split('&');
    const filtered = queryStringList.filter(v => !!v.split('=')[1]);

    return filtered.join('&');
  }

  getOrderQuery = () => {
    return queryString.parse(location.search).order;
  }

  getDistanceQuery = () => {
    return queryString.parse(location.search).distance;
  }

  getUtf8 = () => {
    return $('input:hidden[name="utf8"]').val();
  }

  updateSubmitStatus = (isDisabled) => {
    this.$submit.prop('disabled', isDisabled);
  }

  autoSaveSearchConditionWithSignup = () => {
    if (Cookie.get('search_job_offers_referer') === 'sign_up') {
      // リロードではない場合のみ検索条件を保存する
      if (!this.checkReloadRequest()) {
        this.save();
      }
      Cookie.remove('search_job_offers_referer');
    }
  }

  showSaveSearchConditionModalWithSignin = () => {
    if (Cookie.get('search_job_offers_referer') === 'sign_in') {
      // リロードではない場合のみ検索条件保存モーダルを表示する
      if (!this.checkReloadRequest()) {
        this.modal.show();
      }
      Cookie.remove('search_job_offers_referer');
    }
  }

  checkReloadRequest = () => {
    if (window.performance && window.performance.navigation.type === 1) {
      return true;
    } else {
      return false;
    }
  }

}

export {
  SearchConditionSaveForm,
};
